
import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import { Form, Field, ErrorMessage } from "vee-validate";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import StickerMock from "@/assets/svg/sticker-mock.svg?inline";
import Select from "@/components/atoms/CustomSelect.vue";
import authHeader from "@/services/auth-header";
import Arrow from "@/assets/svg/select-arrow.svg?inline";

export default defineComponent({
  name: "CreateMotivationCard",
  data() {
    return {
      imagePreview: null,
      isPreview: false,
      title: "",
      description: "",
      errorInstructor: null,
      file: null,
      stickerId: null,
      stickerData: {},
      editTeg: false,
      isActive: false,
      motivationData: null,
      studentIds: [],
      dataAllStudents: [],
      dataStudentsName: [],
      errorSchool: null,
    };
  },
  components: {
    ArrowBack,
    Form,
    Select,
    Field,
    // ErrorMessage,
    AlertBlock,
    StickerMock,
    Arrow,
  },
  mounted() {
    this.stickerId = this.$route.query.motivation;
    if (this.$route.query.motivation !== undefined) {
      this.editTeg = true;
      this.getMotivationsCard(this.stickerId);
    }
    this.getStudents();
  },
  methods: {
    getMotivationsCard(id) {
      this.$store.dispatch("motivations/getMotivationCard", id).then(
        (res) => {
          this.motivationData = res.data;
          this.title = this.motivationData.title;
          this.description = this.motivationData.quote;
          this.stickerData.image = this.motivationData.image_url;
          this.studentIds = this.motivationData.student_ids;
          this.motivationData.students.forEach((item) => {
            this.dataStudentsName.push(item.name);
          });
        },
        (error) => {
          this.errorMotivations = error.response.data.errors;
        }
      );
    },
    getStudents() {
      const params = "?n=200";
      this.$store.dispatch("students/getStudents", params).then(
        (res) => {
          this.dataAllStudents = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    createSticker() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("quote", this.description);
      if (this.studentIds.length > 0) {
        this.studentIds.forEach((id) => {
          formData.append("student_ids[]", id);
        });
      }
      if (this.file !== null) {
        formData.append("image", this.file);
      }
      if (this.editTeg === false) {
        this.axios
          .post(
            `${API_URL}admin/schools/${JSON.parse(
              localStorage.getItem("admin-school-id")
            )}/motivational-cards`,
            formData,
            {
              headers: authHeader(),
            }
          )
          .then(
            () => {
              this.$router.push("/admin/motivational-cards");
            },
            (error) => {
              this.errorInstructor = error.response.data.errors;
            }
          );
      } else {
        this.axios
          .post(
            `${API_URL}admin/schools/${JSON.parse(
              localStorage.getItem("admin-school-id")
            )}/motivational-cards/${this.stickerId}`,
            formData,
            { headers: authHeader() }
          )
          .then(
            () => {
              this.$router.push("/admin/motivational-cards");
            },
            (error) => {
              this.errorInstructor = error.response.data.errors;
            }
          );
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("thumbnail", this.file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.imagePreview = reader.result;
          this.isPreview = true;
          this.stickerData.image = null;
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    handleOptionSelected(selectedOption) {
      console.log(selectedOption);
    },
    toggleSchool(id, event, name) {
      console.log(name, id);
      if (event.target.checked) {
        if (!this.studentIds.includes(id)) {
          this.studentIds.push(id);
          this.dataStudentsName.push(name);
        }
      } else {
        const index = this.studentIds.indexOf(id);
        if (index !== -1) {
          this.studentIds.splice(index, 1);
          this.dataStudentsName.splice(index, 1);
        }
      }
    },
    isChecked(id) {
      console.log(this.studentIds[0]);
      return this.studentIds.includes(this.studentIds[0]);
    },
    toggleActiveClass() {
      this.isActive = !this.isActive;
    },
  },
});
